import * as angular from 'angular';
import ExposureService from '../services/ExposureService';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const EditExposureController = function ($scope, $state, $stateParams, $translate, user, ExposureService) {

  const vm = this;
  const translate = $translate.instant;
  vm.disabled = false;
  vm.user = user;
  vm.diverId = $stateParams.uid;
  vm.exposureId = $stateParams.eid;
  vm.errors = {};
  vm.fields = {
    task_type: [],
    exposure_reason: [],
    exposure_method: [],  // for bs4-select
    exposure_type: [],
    substance_type: [],
    protection: [],
    dirty_equipment_handling: [],
    post_incident_actions: {
      protection_level: [],
    },
  };
  vm.chosen = [];
  vm.exposure = null;
  vm.chosenTaskTypes = [];
  vm.chosenExposureMethods = [];
  vm.chosenExposureTypes = [];
  vm.chosenSubstanceTypes = [];
  vm.chosenExposureReasons = [];
  vm.roles = ['rescue_leader', 'rescue_team_leader', 'situation_leader', 'rescuer_operator', 'rescuer_smokediver', 'rescuer_smokedive_standby', 
    'rescuer_no_scba', 'rescuer_engineer', 'rescuer_ladder', 'rescuer_clearing', 'rescuer_logistics', 'rescuer_post_incident_guard',
    'rescuer_chemicaldiver', 'rescuer_other_cbrne', 'medical', 'fire_investigator', 'trainer', 'equipment_maintenance'];
  vm.task_types = ['leading', 'leading_assistance', 'reconnaissance', 'field_search', 'person_rescue', 'evacuation', 'animal_rescue', 
    'firefighting_indoors', 'firefighting_outdoors', 'firefighting_terrain', 'firefighting_other', 'checking_nearby_spaces', 'fire_prevention',
    'hazmat', 'damage_prevention', 'environmental_accident', 'traffic_accident', 'on_site_maintenance', 'post_incident_care', 'fire_investigation'];
  vm.taskTypeItems = vm.task_types.map((v, i) => { return { id: i, name: translate(v.toUpperCase()), value: v } });
  vm.exposureMethods = ['rescue_work_unknown_risk', 'danger_zone_work', 'firefighting_scba', 'firefighting_non_scba', 'smokedive', 
    'smokedive_standby', 'clearing', 'smoke_ventilation', 'water_input', 'smokedive_control', 'chemicaldive', 'measurement_activity',
    'neutralization', 'wash_site_activity', 'medical_activity', 'maintenance_site_activity', 'logistics',
    'post_handling_contaminated_equipment', 'other'];
  vm.exposureMethodItems = vm.exposureMethods.map((v, i) => { return { id: i, name: translate(v.toUpperCase()), value: v } });
  vm.exposureTypes = ['airway', 'skin', 'mucous_membrane', 'eyes', 'injection', 'ingestion', 'other'];
  vm.exposureTypeItems = vm.exposureTypes.map((v, i) => { return { id: i, name: translate(v.toUpperCase()), value: v } });
  vm.exposureReasons = ['long_operating_time', 'insufficient_protection', 'anomaly', 'insufficient_danger_zone'];
  vm.exposureReasonItems = vm.exposureReasons.map((v, i) => { return { id: i, name: translate(v.toUpperCase()), value: v } });
  vm.postIncidentProtection = ['full_face_mask', 'motor_mask', 'ffp1', 'ffp2', 'ffp3', 'vinyl_gloves', 'tyvek_suit', 
    'rubber_gloves', 'apron', 'protective_glasses', 'protective_visor'];
  vm.postIncidentProtectionItems =  vm.postIncidentProtection.map((v, i) => { return { id: i, name: translate(v.toUpperCase()), value: v } });
  vm.protection = ['fire_suit_protective_film', 'fire_suit_no_protective_film', 'fire_helmet', 'fire_gloves', 'smoke_dive_mask', 
    'breathing_apparatus', 'ski_mask', 'fire_boots', 'secure_boots', 'cotton_gloves', 'service_outfit', 'middle_suit', 'forest_fire_suit', 
    'technical_suit', 'tyvek_suit', 'gas_chemical_suit', 'liquid_chemical_suit', 'splash_proof_chemical_suit', 'cold_suit', 'active_carbon_suit', 
    'chemical_gloves', 'protective_glasses', 'protective_visor', 'full_face_mask', 'motor_mask_particle_filter', 'motor_mask_gas_filter', 
    'respirator_filter_ffp2_ffp3'];
  vm.protectionItems = vm.protection.map((v, i) => { return { id: i, name: translate(v.toUpperCase()), value: v } });
  vm.substanceTypes = ['pah_compounds', 'asbestos', 'carbon_fibers_composites', 'hazardous_chemicals', 'radiation', 'radioactive_material', 'other']
  vm.substanceTypeItems = vm.substanceTypes.map((v, i) => { return { id: i, name: translate(v.toUpperCase()), value: v } });
  vm.radiationTypes = ['alpha', 'beta', 'gamma', 'neutron', 'x-ray'];
  vm.radiationUnits = ['mSv', 'uSv', 'nSv'];
  vm.dirtyEquipmentHandling = ['maintenance_unit_on_site', 'maintenance_area_defined', 'maintenance_area_responsible_designated', 
    'maintenance_protection_adequate', 'maintenance_equipment_prewashed', 'maintenance_bagging', 'maintenance_unit_transport',
    'maintenance_own_unit_transport'];
  vm.dirtyEquipmentHandlingItems = vm.dirtyEquipmentHandling.map((v, i) => { return { id: i, name: translate(v.toUpperCase()), value: v } });
  vm.anomalyReportItems = ['incident_report_created', 'close_call_report_created'];

  vm.checked = true;
  vm.taskTypeCallback = function (add, option, model, evt) {
    vm.chosenTaskTypes = model.map((v) => v.value);
  }
  vm.exposureReasonCallback = function (add, option, model, evt) {
    vm.chosenExposureReason = model.map((v) => v.value);
  }
  vm.exposureMethodCallback = function (add, option, model, evt) {
    vm.chosenExposureMethod = model.map((v) => v.value);
  }
  vm.exposureTypeCallback = function (add, option, model, evt) {
    vm.chosenExposureTypes = model.map((v) => v.value);
  }
  vm.substanceTypeCallback = function (add, option, model, evt) {
    vm.chosenSubstanceTypes = model.map((v) => v.value);
  }

  ExposureService.get(vm.exposureId).then((exposure) => {
    vm.exposure = exposure;
    vm.chosenTaskTypes = exposure.task_type && Object.keys(exposure.task_type) || [],
    vm.chosenExposureMethod = exposure.exposure_method && Object.keys(exposure.exposure_method) || [],
    vm.chosenExposureReason = exposure.exposure_reason && Object.keys(exposure.exposure_reason) || [],
    vm.chosenExposureTypes = exposure.exposure_type && Object.keys(exposure.exposure_type) || [],
    vm.chosenSubstanceTypes = exposure.substance_type && Object.keys(exposure.substance_type) || [],

    vm.fields = {
      ...vm.exposure,
      time_start: dayjs(vm.exposure.time_start).utc().second(0).millisecond(0).toDate(),
      protection: vm.protectionItems.filter((v) => vm.exposure.protection && vm.exposure.protection[v.value]),
      post_incident_actions: {
        protection_level: vm.postIncidentProtectionItems.filter((v) => vm.exposure.post_incident_actions?.protection_level[v.value]),
      },
      task_type: vm.taskTypeItems.filter((v) => vm.exposure.task_type && vm.exposure.task_type[v.value]),
      exposure_method: vm.exposureMethodItems.filter((v) => vm.exposure.exposure_method && vm.exposure.exposure_method[v.value]),
      exposure_reason: vm.exposureReasonItems.filter((v) => vm.exposure.exposure_reason && vm.exposure.exposure_reason[v.value]),
      exposure_type: vm.exposureTypeItems.filter((v) => vm.exposure.exposure_type && vm.exposure.exposure_type[v.value]),
      substance_type: vm.substanceTypeItems.filter((v) => vm.exposure.substance_type && vm.exposure.substance_type[v.value]),
      dirty_equipment_handling: vm.dirtyEquipmentHandlingItems.filter((v) => vm.exposure.dirty_equipment_handling && 
        vm.exposure.dirty_equipment_handling[v.value]),
      equipment_cleaning: {
        number_of_washes: vm.exposure.equipment_cleaning?.number_of_washes || 0,
        equipment_destroyed: vm.exposure.equipment_cleaning?.equipment_destroyed || false,
        ozonisation: vm.exposure.equipment_cleaning?.ozonisation || false,
        additional_care_required: vm.exposure.equipment_cleaning?.additional_care_required || false,
      }
    };
  });

  vm.updateExposure = function () {
    const data = {
      ...vm.fields,
      user_id: vm.diverId,
      time_start: dayjs(vm.fields.time_start).valueOf(),
      task_type: Object.fromEntries(vm.fields.task_type.map((v) => [v.value, true])),
      exposure_reason: Object.fromEntries(vm.fields.exposure_reason.map((v) => [v.value, true])),
      exposure_method: Object.fromEntries(vm.fields.exposure_method.map((v) => [v.value, true])),
      exposure_type: Object.fromEntries(vm.fields.exposure_type.map((v) => [v.value, true])),
      substance_type: Object.fromEntries(vm.fields.substance_type.map((v) => [v.value, true])),
      protection: Object.fromEntries(vm.fields.protection.map((v) => [v.value, true])),
      dirty_equipment_handling: Object.fromEntries(vm.fields.dirty_equipment_handling.map((v) => [v.value, true])),
      post_incident_actions: {
        ...vm.fields.post_incident_actions,
        protection_level: Object.fromEntries(vm.fields.post_incident_actions.protection_level.map((v) => [v.value, true])),
      },
      equipment_cleaning: {
        ...vm.fields.equipment_cleaning,
      }
    }
    vm.showAlert = false;
    vm.alertClass = null;
    vm.alertMessage = null;

    ExposureService.update(data).then(() => {
      $state.go('index.users.organization.exposures', { uid: vm.diverId });
    }, (err) => {
      vm.showAlert = true;
      vm.alertClass = 'danger';
      vm.alertMessage = translate('COULD_NOT_UPDATE_EXPOSURE');
      vm.errors = err.errors;
      console.log(err);
      Object.values(err.errors).forEach((error) => {
        if (error.path) {
          $(`#${error.path}`).addClass('is-invalid');
        } else {
          $(`#${key}`).addClass('is-invalid');
        }
      });
    });
  };
}

EditExposureController.$inject = ['$scope', '$state', '$stateParams', '$translate', 'user', 'ExposureService'];

export default angular.module('EditExposureController', [ExposureService]).controller('EditExposureController', EditExposureController).name;
